import React from 'react';
import HeaderNav from './HeaderNav.js'
import pic13 from './../assets/about-13.jpg';
import { useMusicPlayer } from '../context/MusicPlayerContext';
import GlobalMusicPlayer from './GlobalMusicPlayer';
import tracks from '../music_data/tracks';

import './../styles/App.scss';

const Prev = () => {
    const { currentTrack } = useMusicPlayer();
    const reactText = "<Tweet/>";

    return (
        <section className="about">
            <div className="about-main">
            <div className="about-section">
                    <div className="section-title"><h2>/prev</h2></div>
                    <div className="section-content">
                        <p>A list of things I've done previously</p>
                    </div>
                </div>
                <div className="about-section">
                    <div className="section-title"><h2>places I've worked</h2></div>
                    <div className="section-content">
                        <ul>
                             <li>
                                <p><b>hook music</b></p>
                                <p>Worked as a contract software engineer to build a web data annotation tool for their <a href="https://hookmusic.com/" target="_blank">music library</a>.</p>
                            </li>
                            <br />
                            <li>
                                <p><b>fiction bar / cafe</b></p>
                                <p>Worked as a barista + bartender at my <a href="https://maps.app.goo.gl/E7MWz4X5pGWtb1BT9" target="_blank">local cafe/bar</a>. Learned to make a killer vanilla latte. Helped the owner with building his online coffee business and met incredible people in my community.</p>
                            </li>
                            <br />
                            <li>
                                <p><b>twitter</b></p>
                                <p>Product manager. Built a <a href="https://help.x.com/en/safety-and-security/safety-mode" target="_blank">safety mode</a> feature used by Nancy Pelosi and killed by Elon Musk. Experimented with recommending related tweets on conversations. Shipped web platform features, including a re-usable <a href="https://twitter.com/andrs/status/1503721232688160774" target="_blank">tweet component</a>. Went through engineering orientation so I could fix a spacing bug on the {reactText}.</p>
                            </li>
                            <br />
                            <li>
                                <p><b>cisco</b></p>
                                <p>Software engineer intern. Built a microservice for managing role access of Control Center.</p>
                            </li>
                            <br />
                            <li>
                                <p><b>california path</b></p>
                                <p>Software engineer intern. Worked at <a href="https://path.berkeley.edu/about" target="_blank">Berkeley lab</a> building prototypes from transportation research. Designed and built a mobile app giving bus riders the option to search for local bus stops, find ETAs, and pay for trips based on partial bus trips. A transformational summer where I learned how to move from design to full stack app.</p>
                            </li>
                            <br />
                            <li>
                                <p><b>missionU</b></p>
                                <p>Data Engineering intern. Wore many hats at a data science education bootcamp. Developed curriculum based on Python, SQL, and Spreadsheets + witnessed the pacing of a startup.</p>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div className="about-section">
                    <div className="section-title"><h2>college</h2></div>
                    <div className="section-content">
                        <ul>
                            <li>
                                <p><b>accel scholars</b></p>
                                <p><a href="https://eecs.berkeley.edu/resources/undergrads/accel" target="_blank">Program</a>/community of engineering students at Berkeley</p>
                                <p></p>
                            </li>
                            <br />
                            <li>
                                <p><b>free ventures</b></p>
                                <p>Managing director. Led a pre-seed <a href="https://www.freeventures.org/" target="_blank">startup incubator</a> at Berkeley and helped startups raise capital from top firms such as Y Combinator, Greylock, KPCB, and Accel</p>
                                <p></p>
                            </li>
                            <br />
                            <li>
                                <p><b>net impact berkeley</b></p>
                                <p>A consulting club focused on social impact and sustainability. Met amazing ppl. Built their <a href="https://nib.berkeley.edu/" target="_blank">website</a></p>
                                <p></p>
                            </li>
                            <br />
                            <li>
                                <p><b>cal hacks</b></p>
                                <p>The largest collegiate <a href="https://calhacks.io/" target="_blank">hackathon</a> in the nation. Led the diversity team and Cubstart, an initiative to introduce newcomers to the hackathon space. Also was a water boy</p>
                                <p></p>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div className="about-section">
                    <div className="section-title"><h2>side projects</h2></div>
                    <div className="section-content">
                        <ul>
                            <li>
                                <p><b>mind garden 🌱</b></p>
                                <p>(wip) Drag and drop microblogging. Partially broken, use at own <a href="http://mindgarden.page/" target="_blank">risk</a></p>
                                <p></p>
                            </li>
                            <br />
                            <li>
                                <p><b>block action</b></p>
                                <p>Explored class action settlements distributed via Ethereum. We got to work out of <a href="https://skydeck.berkeley.edu/" target="_blank">Skydeck</a>.</p>
                            </li>
                            <br />
                            <li>
                                <p><b>hibernate</b></p>
                                <p>A housing and subletting <a href="https://github.com/adhivd/rails-final-project" target="_blank">platform</a> for/by berkeley students.</p>
                                <p></p>
                            </li>
                            <br />
                            <li>
                                <p><b>self check</b></p>
                                <p>iOS app to assist + remind people  to conduct skin self examinations. 10k+ downloads, and some Harvard doctor endorsed us.</p>
                            </li>
                            <br />
                            <li>
                                <p><b>hs campaign</b></p>
                                <p>Built a <a href="https://web.archive.org/web/20180309220455/https://hs-campaign.com/" target="_blank">web app</a> made to make student council elections fair and equitable. Sold a subscription deal to our high school. </p>
                                <p></p>
                            </li>
                            <br />
                            <li>
                                <p><b>action</b></p>
                                <p>Meeting follow-up <a href="https://web.archive.org/web/20170521022925/https://meetaction.com/" target="_blank">application</a> embedded into Google Docs. Launched on <a href="https://www.producthunt.com/posts/action" target="_blank">product hunt</a> + wrote a <a href="https://medium.com/1517/launching-on-product-hunt-a-high-schooler-s-experience-ca836b40e21b" target="_blank">retrospective</a>.</p>
                                <p></p>
                            </li>
                            <br />
                            <li>
                                <p><b>classfinder</b></p>
                                <p>A class-finding app + social network. Over the years, we expanded to five schools + thousands of users. <a href="https://web.archive.org/web/20120702154924/http://classfinder.me/" target="_blank">archive 2012</a>, <a href="https://web.archive.org/web/20170402153325/http://classfinder.me/" target="_blank">2017</a></p>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div className="about-section">
                    <div className="section-title"><h2>teaching</h2></div>
                    <div className="section-content">
                        <ul>
                            <li>
                                <p><b>react decal</b></p>
                                <p>TA for Berkeley's first React <a href="https://github.com/reactdecal" target="_blank">course</a>, a popular front-end library for building user interfaces (<a href="https://web.archive.org/web/20190730132714/https://reactdecal.org/" target="_blank">archive</a>)</p>
                            </li>
                            <br />
                            <li>
                                <p><b>cubstart</b></p>
                                <p>Initiative from cal hacks to introduce newcomers to the <a href="https://www.cubstart.com/" target="_blank">hackathon</a> space</p>
                            </li>
                            <br />
                            <li>
                                <p><b>ruby on rails decal</b></p>
                                <p>TA for Berkeley course on rails, a web MVC <a href="https://www.notion.so/Rails-DeCal-66b807a01bdd4de6b75bcd0707a81218" target="_blank">framework</a> for building full-stack applications quickly.</p>
                            </li>
                            <br />
                            <li>
                                <p><b>develop academy</b></p>
                                <p>Started a <a href="https://www.mercurynews.com/2017/05/26/lynbrook-high-school-students-give-back-by-leading-educational-summer-camp/" target="_blank">summer camp</a> focused on teaching web development, programming, and graphic design. Received an award from Congress. </p>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div className="about-section">
                    <div className="section-title"></div>
                    <div className="section-content">
                        <h2 className="caption">Last updated March 22nd, 2025</h2>
                    </div>
                </div>
            </div>
            
            <div className="about-sidebar">
                <div className="about-sidebar-img">
                    <img src={pic13} alt="pic13" />
                    {currentTrack && (
                    <div className="about-sidebar-player">
                        <GlobalMusicPlayer tracks={tracks} />
                    </div>
                )}
                <br />
                </div>
                
            </div>
        </section>
    );
};

export default Prev;
