import React, {Component} from 'react';
import HeaderNav from './HeaderNav.js'
import { useMusicPlayer } from '../context/MusicPlayerContext';
import { NavLink } from 'react-router-dom';
import GlobalMusicPlayer from './GlobalMusicPlayer';
import tracks from '../music_data/tracks';

import pic1 from './../assets/about_1.png';
import pic2 from './../assets/about_2.png';
import pic3 from './../assets/about_3.png';
import pic4 from './../assets/about_4.png';
import pic5 from './../assets/about_5.png';
import pic6 from './../assets/about_6.png';
import pic7 from './../assets/about_7.png';
import pic8 from './../assets/about_8.png';
import pic9 from './../assets/about_9.png';
import pic10 from './../assets/about-10.jpg';
import pic11 from './../assets/about-11.jpg';
import pic12 from './../assets/about-12.jpg';
import pic13 from './../assets/about-13.jpg';
import pic14 from './../assets/about-14.jpg';

import Animation from './Animation';

import './../styles/App.scss';

const About = () => {
    const { currentTrack } = useMusicPlayer();

    return (
        <section className="about">
            <div className="about-main">
                <div className="about-section">
                    <div className="section-title"><h2>/about</h2></div>
                    <div className="section-content">
                        <p>Hi! My name is adhiv dhar – welcome to my corner of the internet. I'm a music producer living in brooklyn new york. I make electronic and rnb/hip hop music. Previously, I graduated from uc berkeley with a degree in eecs and managed products at twitter.</p>
                        <br />
                        <p>I spend most of my time creating stuff. When I'm not doing that, I go on walks, journal, cook, meet friends for drinks, and listen to music (lately: james blake, kendrick lamar, troy boi, flume, mac miller, jai paul). Born and raised in the bay area and intensely missing my dog.</p>
                    </div>
                </div>
                <div className="about-section">
                    <div className="section-title"><h2>my interests</h2></div>
                    <div className="section-content">
                        <ul>
                            <li><p><NavLink to="/music">music production</NavLink></p></li>
                            <li><p>web development</p></li>
                            <li><p>disc jockey</p></li>
                            <li><p>collage & multimedia art</p></li>
                            <li><p>dancing</p></li>
                            <li><p>journaling</p></li>
                            <li><p>startups</p></li>
                            <li><p>graphic design</p></li>
                            <li><p>photography</p></li>
                        </ul>
                    </div>
                </div>
                <div className="about-section">
                    <div className="section-title"><h2>hire me</h2></div>
                    <div className="section-content">
                        <p>Currently open to freelance web + product work. I'm a full stack web developer who can design, develop, and ship products with strong empathy for users. Email me if you'd like to chat.</p>
                    </div>
                </div>
                <div className="about-section">
                    <div className="section-title"><h2>contact</h2></div>
                    <div className="section-content">
                        <p>adhivd [at] gmail [dot] com</p>
                    </div>
                </div>
                <div className="about-section">
                    <div className="section-title"><h2>links</h2></div>
                    <div className="section-content">
                        <p>other parts of the internet you can find me:</p>
                        <ul>
                            <li><p><a href="https://www.youtube.com/@dharbeats/" target="_blank">youtube</a></p></li>
                            <li><p><a href="https://github.com/adhivd" target="_blank">github</a></p></li>
                            <li><p><a href="https://www.instagram.com/adhivd/" target="_blank">instagram</a></p></li>
                            <li><p><a href="https://www.twitter.com/adhivdhar/" target="_blank">twitter</a></p></li>
                            <li><p><a href="https://www.tiktok.com/@adhivd/" target="_blank">tiktok</a></p></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="about-sidebar">
                <div className="about-sidebar-img">
                    <img src={pic10} alt="pic10" />
                    <br />
                    {currentTrack && (
                    <div className="about-sidebar-player">
                        <GlobalMusicPlayer tracks={tracks} />
                    </div>
                )}
                </div>
                
              
                
            </div>
        </section>
    );
};

export default About;
