import React, { useEffect, useState } from 'react';
import { useAudio } from '../context/AudioContext';
import * as Tone from 'tone';
import './KeyboardPlayer.css';

const KeyboardPlayer = () => {
    const [isAudioInitialized, setIsAudioInitialized] = useState(false);
    const [animationFrame, setAnimationFrame] = useState(0);
    const { 
        isPlaying, 
        handlePlayback, 
        playNote, 
        releaseNote, 
        initializeTone, 
        activeNotes,
        keyMap,
        isInitialized 
    } = useAudio();

    // Add a new state for showing initialization message
    const [showInitMessage, setShowInitMessage] = useState(true);

    // Define the keyboard layout
    const whiteKeys = ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ';', "'"];
    const blackKeys = ['w', 'e', 't', 'y', 'u', 'o', 'p'];

    // Colors for the keys when active
    const colors = [
        '#FF9AA2', // Pastel Red
        '#B5EAD7', // Pastel Green
        '#C7CEEA', // Pastel Blue
        '#FFDAC1', // Pastel Yellow/Orange
        '#E2F0CB', // Pastel Light Green
        '#CFBAF0', // Pastel Purple
        '#F1C0E8', // Pastel Pink
        '#A2D2FF'  // Pastel Light Blue
    ];

    // Get a random color for a key
    const getRandomColor = () => {
        return colors[Math.floor(Math.random() * colors.length)];
    };

    // Dispatch note play event
    const dispatchNotePlayEvent = () => {
        window.dispatchEvent(new CustomEvent('notePlay'));
    };

    // Initialize audio context with user interaction
    const initializeAudio = async () => {
        if (!isAudioInitialized) {
            try {
                await initializeTone();
                // Double check if we're actually initialized
                if (Tone.context.state === 'running') {
                    setIsAudioInitialized(true);
                    setShowInitMessage(false);
                    setIsFirstTouch(false);
                } else {
                    console.warn('Audio context not running after initialization attempt');
                }
            } catch (error) {
                console.error('Error initializing audio:', error);
            }
        }
        return isAudioInitialized;
    };

    // Modified key click handler with better error handling
    const handleKeyClick = async (key) => {
        try {
            // Always try to initialize audio on first interaction
            if (!isAudioInitialized) {
                await initializeAudio();
                // If this is the first touch on mobile, don't play the note yet
                if (isFirstTouch && ('ontouchstart' in window)) {
                    return;
                }
            }

            const note = keyMap[key];
            if (note) {
                await playNote(note, key);
                dispatchNotePlayEvent();
            }
        } catch (error) {
            console.error('Error handling key click:', error);
        }
    };

    // Modified playback handler with better error handling
    const handlePlaybackWithSparks = async () => {
        try {
            if (!isAudioInitialized) {
                await initializeAudio();
            }
            await handlePlayback();
        } catch (error) {
            console.error('Error handling playback:', error);
        }
    };

    useEffect(() => {
        const handleKeyDown = async (e) => {
            const key = e.key.toLowerCase();
            const note = keyMap[key];
            
            if (note && !e.repeat && !activeNotes.has(key)) {
                if (!isAudioInitialized) {
                    await initializeAudio();
                }
                playNote(note, key);
                dispatchNotePlayEvent();
            }
        };

        const handleKeyUp = (e) => {
            const key = e.key.toLowerCase();
            const note = keyMap[key];
            if (note) {
                releaseNote(note, key);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [activeNotes, isAudioInitialized]);

    const handleKeyRelease = (key) => {
        const note = keyMap[key];
        if (note) {
            releaseNote(note, key);
        }
    };

    // Handle song playback effects
    useEffect(() => {
        let noteInterval;
        if (isPlaying) {
            noteInterval = setInterval(() => {
                dispatchNotePlayEvent();
            }, 300); // Adjust timing for song tempo
        }
        return () => {
            if (noteInterval) {
                clearInterval(noteInterval);
            }
        };
    }, [isPlaying]);

    const [isFirstTouch, setIsFirstTouch] = useState(true);

    // Animation frames for the rose button
    const roseFrames = isPlaying ? [
        { rose: '⏸️', arrows: '<   >' },
        { rose: '⏸️', arrows: '<   >' },
        { rose: '⏸️', arrows: '<   >' },
        { rose: '⏸️', arrows: '<   >' }
    ] : [
        { rose: '🌹', arrows: '' },
        { rose: '🌹', arrows: '<   >' },
        { rose: '🌹', arrows: '<<   >>' },
        { rose: '🌹', arrows: '<<<   >>>' }
    ];

    // Animation effect
    useEffect(() => {
        const interval = setInterval(() => {
            setAnimationFrame((prev) => (prev + 1) % roseFrames.length);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    // Add a touch event listener to the entire keyboard component for initialization
    useEffect(() => {
        const handleGlobalTouch = async () => {
            if (!isAudioInitialized) {
                await initializeAudio();
            }
        };

        const keyboardElement = document.querySelector('.keyboard-player');
        if (keyboardElement) {
            keyboardElement.addEventListener('touchstart', handleGlobalTouch, { passive: true });
        }

        return () => {
            if (keyboardElement) {
                keyboardElement.removeEventListener('touchstart', handleGlobalTouch);
            }
        };
    }, [isAudioInitialized]);

    const renderKey = (key, isBlack = false) => {
        const isActive = activeNotes.has(key);
        const style = {
            backgroundColor: isActive ? activeNotes.get(key) : '#f0f0f0',
            transform: isActive ? 'translateY(-2px)' : 'translateY(0)',
            transition: 'all 0.1s ease-out'
        };

        const handleTouchStart = async (e) => {
            e.stopPropagation();
            
            // First, try to initialize audio if needed
            if (!isAudioInitialized) {
                const initialized = await initializeAudio();
                if (!initialized) {
                    return; // Don't prevent default if we're still trying to initialize
                }
            }

            // Only prevent default and handle the note if we're initialized
            if (isAudioInitialized) {
                e.preventDefault();
                if (!isActive) {
                    handleKeyClick(key);
                }
            }
        };

        const handleTouchEnd = (e) => {
            e.stopPropagation();
            // Only prevent default and handle note release if we're initialized and the note is active
            if (isAudioInitialized && isActive) {
                e.preventDefault();
                handleKeyRelease(key);
            }
        };

        return (
            <div
                key={key}
                className={`keyboard-key ${isBlack ? 'black-key' : 'white-key'} ${isActive ? 'active' : ''}`}
                style={style}
                onMouseDown={() => !isActive && handleKeyClick(key)}
                onMouseUp={() => isActive && handleKeyRelease(key)}
                onMouseLeave={() => isActive && handleKeyRelease(key)}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onTouchCancel={handleTouchEnd}
            >
                {key}
            </div>
        );
    };

    return (
        <div className="keyboard-player">
            {showInitMessage && (
                <div className="init-message">
                    / play me /
                </div>
            )}
            <div className="visual-keyboard">
                <div className="black-keys">
                    {blackKeys.map(key => renderKey(key, true))}
                </div>
                <div className="white-keys">
                    {whiteKeys.map(key => renderKey(key, false))}
                </div>
            </div>
            <button 
                className="play-midi-button"
                onClick={handlePlaybackWithSparks}
                aria-label={isPlaying ? 'Stop playing' : 'Play La Vie en Rose'}
            >
                <span className="rose">{roseFrames[animationFrame].rose}</span>
                <span className="arrows">{roseFrames[animationFrame].arrows}</span>
            </button>
        </div>
    );
};

export default KeyboardPlayer; 