import React from 'react';
import HeaderNav from './HeaderNav.js'
import { useMusicPlayer } from '../context/MusicPlayerContext';
import GlobalMusicPlayer from './GlobalMusicPlayer';
import tracks from '../music_data/tracks';

import './../styles/App.scss';
import pic8 from './../assets/about_8.png';
import now1 from './../assets/now-1.jpg';

const Now = () => {
    const { currentTrack } = useMusicPlayer();
    
    return (
      <section className="about">
          <div className="about-main">
              <div className="about-section">
                  <div className="section-title"><h2>/now</h2></div>
                  <div className="section-content">
                    <p>Rebuilt adhiv.com for it's 5th major rehaul. Getting back into writing. Just finished up a web dev contract role. Growing out a mustache.</p>
                    <br />
                    <p>Travelling to Japan for two and a half weeks in April, which I'm excited for (send me your recs).</p>
                   
                  </div>
              </div>
        
              <div className="about-section">
                  <div className="section-title"><h2>music</h2></div>
                  <div className="section-content">
                      <ul>
                      <p>Released "your orbit" my first streamable single as a producer. <a href="https://distrokid.com/hyperfollow/adhivdharxthirdculture/your-orbit" target="_blank">listen here</a>.</p>

                    <br />

                    <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/track/63Ab6EmbCjnbo9JG488m91?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                    <br /><br />
                          <p>Karthik recently dropped a third culture project called <a href="https://open.spotify.com/album/4utgu6dXSYsibPV6I15St8" target="_blank">all the neighbhorood kids are grown</a>. I produced the first three tracks (ps1, casso, and a night at st. regis).</p>

                          <br />

                          <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/album/4pMjf20rryWJVRTx3YaEeJ?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                          
                          <br />
                      </ul>
                  </div>
              </div>
              <div className="about-section">
                  <div className="section-title"><h2>reading</h2></div>
                  <div className="section-content">
                      <p>The Artist's Way by Julia Cameron</p>
                  </div>
              </div>
          </div>
          <div className="about-sidebar">
              <div className="about-sidebar-img">
                  <img src={now1} alt="profile" />
                  <br />
                  <h2>Inspired by Derek Sivers' <a href="https://nownownow.com/about" target="_blank">/now</a> page. Last updated March 22, 2025</h2> 
                  <br />
                  {currentTrack && (
                  <div className="about-sidebar-player">
                      <GlobalMusicPlayer tracks={tracks} />
                  </div>
              )}
               <br />
              
            </div>
          </div>
      </section>
    );
}

export default Now;
