import React, { createContext, useContext, useState, useEffect } from 'react';
import * as Tone from 'tone';
import roseData from '../assets/music/rose.json';

const AudioContext = createContext();

// Map keyboard keys to notes
const keyMap = {
    'a': 'C4',
    's': 'D4',
    'd': 'E4',
    'f': 'F4',
    'g': 'G4',
    'h': 'A4',
    'j': 'B4',
    'k': 'C5',
    'l': 'D5',
    ';': 'E5',
    "'": 'F5',
    'w': 'C#4',
    'e': 'D#4',
    't': 'F#4',
    'y': 'G#4',
    'u': 'A#4',
    'o': 'C#5',
    'p': 'D#5'
};

// Colors for the keys when active
const colors = [
    '#FF9AA2', // Pastel Red
    '#B5EAD7', // Pastel Green
    '#C7CEEA', // Pastel Blue
    '#FFDAC1', // Pastel Yellow/Orange
    '#E2F0CB', // Pastel Light Green
    '#CFBAF0', // Pastel Purple
    '#F1C0E8', // Pastel Pink
    '#A2D2FF'  // Pastel Light Blue
];

// Get a random color
const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
};

export const AudioProvider = ({ children }) => {
    const [synth, setSynth] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentPart, setCurrentPart] = useState(null);
    const [activeNotes, setActiveNotes] = useState(new Map());

    // Add event listener for music player
    useEffect(() => {
        const handleMusicPlayerStart = () => {
            if (isPlaying) {
                // Stop La Vie en Rose if it's playing
                if (currentPart) {
                    currentPart.stop(0);
                    currentPart.cancel(0);
                }
                Tone.Transport.stop();
                Tone.Transport.position = 0;
                setIsPlaying(false);
                setActiveNotes(new Map());
            }
        };

        window.addEventListener('musicPlayerStarted', handleMusicPlayerStart);
        return () => {
            window.removeEventListener('musicPlayerStarted', handleMusicPlayerStart);
        };
    }, [isPlaying, currentPart]);

    // Check if audio context is already running
    useEffect(() => {
        if (Tone.context.state === 'running') {
            setIsInitialized(true);
        }
    }, []);

    const initializeTone = async () => {
        try {
            // If context is already running, we're good to go
            if (Tone.context.state === 'running') {
                setIsInitialized(true);
                return;
            }

            // Start Tone.js
            await Tone.start();
            
            // Create and play a silent buffer to unlock audio on mobile
            const silentBuffer = Tone.context.createBuffer(1, 1, 22050);
            const bufferSource = Tone.context.createBufferSource();
            bufferSource.buffer = silentBuffer;
            bufferSource.connect(Tone.context.destination);
            bufferSource.start();

            // Ensure the context is running
            if (Tone.context.state !== 'running') {
                await Tone.context.resume();
            }
            
            setIsInitialized(true);
        } catch (error) {
            console.error('Error initializing Tone.js:', error);
            // Try to resume the context even if there was an error
            if (Tone.context.state !== 'running') {
                try {
                    await Tone.context.resume();
                    if (Tone.context.state === 'running') {
                        setIsInitialized(true);
                    }
                } catch (resumeError) {
                    console.error('Error resuming audio context:', resumeError);
                }
            }
        }
    };

    const handlePlayback = async () => {
        await initializeTone();
        
        if (isPlaying) {
            if (currentPart) {
                currentPart.stop(0);
                currentPart.cancel(0);
            }
            Tone.Transport.stop();
            Tone.Transport.position = 0;
            setIsPlaying(false);
            setActiveNotes(new Map()); // Clear active notes when stopping
            return;
        }

        try {
            // Dispatch event to pause music player
            window.dispatchEvent(new CustomEvent('keyboardPlayerStarted'));

            // Extract all notes from both tracks
            const allNotes = roseData.tracks.flatMap(track => 
                track.notes.map(note => ({
                    time: note.time,
                    note: note.name,
                    duration: note.duration,
                    velocity: note.velocity
                }))
            );

            // Create a new part for playback
            const part = new Tone.Part((time, note) => {
                // Play the note regardless of whether it has a visual representation
                synth.triggerAttackRelease(note.note, note.duration, time, note.velocity);
                
                // Update visual feedback if the note has a corresponding key
                const key = Object.entries(keyMap).find(([k, n]) => n === note.note)?.[0];
                if (key) {
                    Tone.Draw.schedule(() => {
                        setActiveNotes(prev => new Map(prev).set(key, getRandomColor()));
                        
                        // Schedule the key release
                        setTimeout(() => {
                            setActiveNotes(prev => {
                                const newMap = new Map(prev);
                                newMap.delete(key);
                                return newMap;
                            });
                        }, note.duration * 1000);
                    }, time);
                }
            }, allNotes).start(0);

            // Enable looping
            part.loop = true;
            
            // Calculate the total duration of the song
            const lastNote = allNotes[allNotes.length - 1];
            const songDuration = lastNote.time + lastNote.duration;
            
            // Add a 0.3 second delay before looping
            const loopEndWithDelay = songDuration + 0.3;
            
            // Set the loop points
            part.loopStart = 0;
            part.loopEnd = loopEndWithDelay;
            
            // Configure Transport for looping
            Tone.Transport.loop = true;
            Tone.Transport.loopStart = 0;
            Tone.Transport.loopEnd = loopEndWithDelay;

            setCurrentPart(part);
            
            // Start playback
            Tone.Transport.start();
            setIsPlaying(true);
        } catch (error) {
            console.error('Error playing music:', error);
        }
    };

    useEffect(() => {
        // Create a limiter to prevent clipping
        const limiter = new Tone.Limiter(-6).toDestination();
        
        // Create a custom synth with pluck-like characteristics
        const newSynth = new Tone.PolySynth(Tone.Synth, {
            oscillator: {
                type: "sine",
                volume: -6
            },
            envelope: {
                attack: 0.01,
                decay: 0.5,
                sustain: 0.1,
                release: 0.5,
                curve: "exponential"
            },
            volume: -6
        }).connect(limiter);

        setSynth(newSynth);

        // Cleanup on unmount
        return () => {
            if (newSynth) {
                newSynth.dispose();
                limiter.dispose();
            }
            if (currentPart) {
                currentPart.dispose();
            }
            Tone.Transport.stop();
        };
    }, []);

    const playNote = async (note, key) => {
        if (!synth) return;

        try {
            // If context is already running from another component, we can just use it
            if (Tone.context.state === 'running') {
                setIsInitialized(true);
            } else if (!isInitialized) {
                await initializeTone();
            }
            
            // One final check to ensure context is running
            if (Tone.context.state !== 'running') {
                await Tone.context.resume();
            }
            
            synth.triggerAttack(note);
            setActiveNotes(prev => new Map(prev).set(key, getRandomColor()));
        } catch (error) {
            console.error('Error playing note:', error);
        }
    };

    const releaseNote = (note, key) => {
        if (synth && isInitialized) {
            synth.triggerRelease(note);
            setActiveNotes(prev => {
                const newMap = new Map(prev);
                newMap.delete(key);
                return newMap;
            });
        }
    };

    return (
        <AudioContext.Provider value={{
            synth,
            isInitialized,
            isPlaying,
            activeNotes,
            handlePlayback,
            playNote,
            releaseNote,
            initializeTone,
            keyMap
        }}>
            {children}
        </AudioContext.Provider>
    );
};

export const useAudio = () => {
    const context = useContext(AudioContext);
    if (!context) {
        throw new Error('useAudio must be used within an AudioProvider');
    }
    return context;
}; 