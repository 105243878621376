import React, { Component } from 'react';
import HeaderNav from './HeaderNav.js';
import { NavLink } from 'react-router-dom';
import Gallery from './Gallery.js';
import Marquee from './Marquee.js';
import KeyboardPlayer from './KeyboardPlayer';
import CircleAnimation from './CircleAnimation';

import Animation from './Animation';
import Background from './Background';
import external from './../assets/external-link.png';
import { Link, withRouter } from 'react-router-dom';
import pic1 from './../assets/about_1.png';
import pic2 from './../assets/about_2.png';
import pic9 from './../assets/about_9.png';
import fullstop from './../assets/fullstop.png';

import dvd from './../assets/dvd.png';
import DvdLogo from './../assets/DvdLogo';

import './../styles/App.scss';
import './../styles/Home.scss';
import './../styles/KeyboardPlayer.scss';

class Home extends Component {
    constructor(props) {
        super(props);
        
        // Calculate the initial DVD logo size based on screen dimensions
        const isMobile = window.innerWidth <= 768;
        const baseLogoWidth = isMobile ? 
            Math.min(280, window.innerWidth * 0.35) : // Larger on mobile but still constrained
            Math.min(220, window.innerWidth * 0.25);
        const baseLogoHeight = baseLogoWidth * 0.5;  // Maintain aspect ratio
        
        this.state = {
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            blogHovered: false,
            audioUnlocked: false,
            dvdLogos: [
                {
                    id: 1,
                    x: Math.random() * (window.innerWidth - baseLogoWidth),
                    y: Math.random() * (window.innerHeight - baseLogoHeight),
                    velocityX: 2 * (Math.random() > 0.5 ? 1 : -1),
                    velocityY: 2 * (Math.random() > 0.5 ? 1 : -1),
                    width: baseLogoWidth,
                    height: baseLogoHeight,
                    color: this.getRandomColor()
                }
            ]
        };
        this.animationFrameId = null;
        this.silentAudio = new Audio();
        this.silentAudio.src = 'data:audio/mp3;base64,SUQzBAAAAAAAI1RTU0UAAAAPAAADTGF2ZjU4LjI5LjEwMAAAAAAAAAAAAAAA//OEAAAAAAAAAAAAAAAAAAAAAAAASW5mbwAAAA8AAAACAAABIADAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDV1dXV1dXV1dXV1dXV1dXV1dXV1dXV1dXV6urq6urq6urq6urq6urq6urq6urq6urq6v////////////////////////////////8AAAAATGF2YzU4LjU0AAAAAAAAAAAAAAAAJAAAAAAAAAAAASDs90hvAAAAAAAAAAAAAAAAAAAA//MUZAAAAAGkAAAAAAAAA0gAAAAATEFN//MUZAMAAAGkAAAAAAAAA0gAAAAARTMu//MUZAYAAAGkAAAAAAAAA0gAAAAAOTku//MUZAkAAAGkAAAAAAAAA0gAAAAANVVV';
        this.silentAudio.loop = true;
    }

    unlockAudio = () => {
        if (!this.state.audioUnlocked) {
            // Play the silent audio
            this.silentAudio.play()
                .then(() => {
                    this.setState({ audioUnlocked: true });
                    console.log('Audio context unlocked');
                })
                .catch(error => {
                    console.error('Error unlocking audio:', error);
                });
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.startAnimation();

        // Add click listener to the entire document
        document.addEventListener('click', this.unlockAudio);
        document.addEventListener('touchstart', this.unlockAudio);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        document.removeEventListener('click', this.unlockAudio);
        document.removeEventListener('touchstart', this.unlockAudio);
        cancelAnimationFrame(this.animationFrameId);
        this.silentAudio.pause();
        this.silentAudio.currentTime = 0;
    }

    updateDimensions = () => {
        const isMobile = window.innerWidth <= 768;
        const baseLogoWidth = isMobile ? 
            Math.min(280, window.innerWidth * 0.35) : 
            Math.min(220, window.innerWidth * 0.25);
        const baseLogoHeight = baseLogoWidth * 0.5;

        // Update all DVD logos with new dimensions while maintaining their relative positions
        const updatedLogos = this.state.dvdLogos.map(logo => {
            const widthRatio = baseLogoWidth / logo.width;
            const heightRatio = baseLogoHeight / logo.height;
            
            // Adjust position to maintain relative position and ensure within bounds
            let newX = logo.x * widthRatio;
            let newY = logo.y * heightRatio;
            
            // Ensure the logo stays within bounds after resize
            newX = Math.min(newX, window.innerWidth - baseLogoWidth);
            newY = Math.min(newY, window.innerHeight - baseLogoHeight);
            
            return {
                ...logo,
                x: newX,
                y: newY,
                width: baseLogoWidth,
                height: baseLogoHeight
            };
        });

        this.setState({
            innerWidth: window.innerWidth,
            innerHeight: window.innerHeight,
            dvdLogos: updatedLogos
        });
    }

    startAnimation = () => {
        const animate = () => {
            this.updateDvdLogos();
            this.animationFrameId = requestAnimationFrame(animate);
        };
        this.animationFrameId = requestAnimationFrame(animate);
    }

    updateDvdLogos = () => {
        const { innerWidth, innerHeight, dvdLogos } = this.state;
        
        const updatedLogos = dvdLogos.map(logo => {
            let newX = logo.x + logo.velocityX;
            let newY = logo.y + logo.velocityY;
            let newColor = logo.color;
            let velocityX = logo.velocityX;
            let velocityY = logo.velocityY;
            
            // Bounce off left or right edge
            if (newX <= 0 || newX + logo.width >= innerWidth) {
                velocityX = -velocityX;
                newColor = this.getRandomColor(logo.color);
            }
            
            // Bounce off top or bottom edge
            if (newY <= 0 || newY + logo.height >= innerHeight) {
                velocityY = -velocityY;
                newColor = this.getRandomColor(logo.color);
            }
            
            return {
                ...logo,
                x: newX,
                y: newY,
                velocityX,
                velocityY,
                color: newColor
            };
        });
        
        this.setState({ dvdLogos: updatedLogos });
    }

    handleDvdClick = (id) => {
        const { dvdLogos } = this.state;
        const clickedLogo = dvdLogos.find(logo => logo.id === id);
        
        if (!clickedLogo) return;
        
        // Remove the clicked logo
        const filteredLogos = dvdLogos.filter(logo => logo.id !== id);
        
        // Create two smaller logos
        const isMobile = window.innerWidth <= 768;
        const newWidth = clickedLogo.width * (isMobile ? 0.85 : 0.8); // Slightly larger reduction factor on mobile
        const newHeight = newWidth * 0.5;
        
        const logo1 = {
            id: Date.now(),
            x: clickedLogo.x,
            y: clickedLogo.y,
            velocityX: clickedLogo.velocityX * 1.2,
            velocityY: -clickedLogo.velocityY * 1.1,
            width: newWidth,
            height: newHeight,
            color: this.getRandomColor(clickedLogo.color)
        };
        
        const logo2 = {
            id: Date.now() + 1,
            x: clickedLogo.x,
            y: clickedLogo.y,
            velocityX: -clickedLogo.velocityX * 1.1,
            velocityY: clickedLogo.velocityY * 1.2,
            width: newWidth,
            height: newHeight,
            color: this.getRandomColor(clickedLogo.color)
        };
        
        this.setState({
            dvdLogos: [...filteredLogos, logo1, logo2]
        });
    }

    getRandomColor = (currentColor) => {
        const colors = [
            '#FF9AA2', // Pastel Red
            '#B5EAD7', // Pastel Green
            '#C7CEEA', // Pastel Blue
            '#FFDAC1', // Pastel Yellow/Orange
            '#E2F0CB', // Pastel Light Green
            '#CFBAF0', // Pastel Purple
            '#F1C0E8', // Pastel Pink
            '#A2D2FF'  // Pastel Light Blue
        ];
        // Filter out the current color and pick from remaining colors
        const availableColors = colors.filter(color => color !== currentColor);
        return availableColors[Math.floor(Math.random() * availableColors.length)];
    }

    render() {
        const { dvdLogos } = this.state;
        
        return (
            <div className="home-container">
                <CircleAnimation />
                <HeaderNav />
                
                {/* DVD Logos */}
                <div className="dvd-container">
                    {dvdLogos.map(logo => (
                        <div
                            key={logo.id}
                            className="dvd-logo"
                            onClick={() => this.handleDvdClick(logo.id)}
                            style={{
                                position: 'absolute',
                                left: `${logo.x}px`,
                                top: `${logo.y}px`,
                                width: `${logo.width}px`,
                                height: `${logo.height}px`,
                                cursor: 'pointer',
                                zIndex: 10
                            }}
                        >
                            <DvdLogo color={logo.color} />
                        </div>
                    ))}
                </div>
                
                {/* Centered Headline */}
                <div className="headline-container">
                    <div className="headline-text">
                        <p>
                            Adhiv is a <NavLink to="/music">music</NavLink> producer living in Brooklyn, New York. 
                            Read <NavLink to="/about">about</NavLink> me, what I'm <NavLink to="/now">up to</NavLink>, 
                            or what I <NavLink to="/prev">was</NavLink> up to
                        </p>
                    </div>
                </div>
                
                {/* KeyboardPlayer Component */}
                <KeyboardPlayer />
                
                {/* Marquee Component */}
                <Marquee />
                
                {/* Gallery can be rendered below if needed */}
            </div>
        );
    }
}

export default Home;
