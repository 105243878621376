import React from 'react';
import Home from './Components/Home.js';
import About from './Components/About.js';
import Prev from './Components/Prev.js';
import Music from './Components/Music.js';
import Posts from './Components/Posts.js';
import PostPage from './Components/PostPage.js';
import Writing from './Components/Writing.js';
import { AudioProvider } from './context/AudioContext';
import { MusicPlayerProvider } from './context/MusicPlayerContext';
import ScrollToTop from './Components/ScrollToTop';
import tracks from './music_data/tracks';
import MobileFloatingPlayer from './Components/MobileFloatingPlayer';

import Now from './Components/Now.js';
import GA from './Components/GoogleAnalytics';
import Animation from './Components/Animation';
import AudioPlayer from './Components/newPlayer/AudioPlayer';
import Footer from './Components/Footer';
import HeaderNav from './Components/HeaderNav';

import { BrowserRouter as Router, Route } from "react-router-dom";

import './styles/App.scss';

// Export tracks so they're available globally from App context
export { tracks };

function App() {

  let threedworld = <Animation />

  return (
    <AudioProvider>
      <MusicPlayerProvider>
        <Router>
            <ScrollToTop />
            <HeaderNav/>
            <div className="page">
              <Route exact path="/" component={Home} />
              <Route path="/now" component={Now} />
              <Route path="/about" component={About} />
              <Route path="/prev" component={Prev} />
              <Route path="/music" component={Music} />
              <Route exact path="/posts" component={Posts} />
              <Route path="/posts/:slug" component={PostPage} />
            </div>
            <MobileFloatingPlayer />
            {/* <p><!-- Big thanks to Jonathan Yang + Shiva Vemireddy for looking over iterations of this <3  --></p> */}
            { GA.init() && <GA.RouteTracker /> }
            {/* {threedworld} */}
            
            {/* <Footer /> */}
        </Router>
      </MusicPlayerProvider>
    </AudioProvider>
  );
}

export default App;
