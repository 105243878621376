import React, { createContext, useContext, useState, useRef, useEffect } from 'react';

const MusicPlayerContext = createContext();

export const MusicPlayerProvider = ({ children }) => {
    const [currentTrack, setCurrentTrack] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [volume, setVolume] = useState(0.7);
    const audioRef = useRef(null);

    // Initialize audio element
    if (!audioRef.current) {
        audioRef.current = new Audio();
        audioRef.current.volume = volume;
    }

    // Add event listener for keyboard player
    useEffect(() => {
        const handleKeyboardPlayerStart = () => {
            if (isPlaying && audioRef.current) {
                audioRef.current.pause();
                setIsPlaying(false);
            }
        };

        window.addEventListener('keyboardPlayerStarted', handleKeyboardPlayerStart);
        return () => {
            window.removeEventListener('keyboardPlayerStarted', handleKeyboardPlayerStart);
        };
    }, [isPlaying]);

    const handleTrackSelect = (track) => {
        const wasPlaying = isPlaying;
        
        // If a track is currently playing, stop it
        if (isPlaying) {
            audioRef.current.pause();
        }

        setCurrentTrack(track);
        audioRef.current.src = track.song_src;
        
        // If we were playing before, or this is a new track selection, start playing
        if (wasPlaying || currentTrack?.id !== track.id) {
            // Dispatch event to pause keyboard player
            window.dispatchEvent(new CustomEvent('musicPlayerStarted'));
            
            audioRef.current.play()
                .then(() => {
                    setIsPlaying(true);
                    setDuration(audioRef.current.duration || 0);
                })
                .catch(error => {
                    console.error("Audio playback error:", error);
                });
        }
    };

    const togglePlayPause = () => {
        if (!currentTrack) return;

        if (isPlaying) {
            audioRef.current.pause();
        } else {
            // Dispatch event to pause keyboard player when resuming playback
            window.dispatchEvent(new CustomEvent('musicPlayerStarted'));
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handlePrevious = (tracks) => {
        if (!currentTrack) return;
        const currentIndex = tracks.findIndex(track => track.id === currentTrack.id);
        const prevIndex = (currentIndex === 0) ? tracks.length - 1 : currentIndex - 1;
        handleTrackSelect(tracks[prevIndex]);
    };

    const handleNext = (tracks) => {
        if (!currentTrack) return;
        const currentIndex = tracks.findIndex(track => track.id === currentTrack.id);
        const nextIndex = (currentIndex === tracks.length - 1) ? 0 : currentIndex + 1;
        handleTrackSelect(tracks[nextIndex]);
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    const handleLoadedMetadata = () => {
        setDuration(audioRef.current.duration || 0);
    };

    const handleSeek = (value) => {
        const seekTime = (value / 100) * duration;
        audioRef.current.currentTime = seekTime;
        setCurrentTime(seekTime);
    };

    const handleVolumeChange = (value) => {
        const newVolume = value / 100;
        setVolume(newVolume);
        audioRef.current.volume = newVolume;
    };

    // Format time for display (mm:ss)
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    };

    return (
        <MusicPlayerContext.Provider value={{
            currentTrack,
            isPlaying,
            duration,
            currentTime,
            volume,
            handleTrackSelect,
            togglePlayPause,
            handlePrevious,
            handleNext,
            handleTimeUpdate,
            handleLoadedMetadata,
            handleSeek,
            handleVolumeChange,
            formatTime,
            audioRef
        }}>
            {children}
        </MusicPlayerContext.Provider>
    );
};

export const useMusicPlayer = () => {
    const context = useContext(MusicPlayerContext);
    if (!context) {
        throw new Error('useMusicPlayer must be used within a MusicPlayerProvider');
    }
    return context;
}; 