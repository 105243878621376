import React from 'react';
import HeaderNav from './HeaderNav.js';
import { NavLink } from 'react-router-dom';
import Animation from './Animation';
import { useMusicPlayer } from '../context/MusicPlayerContext';
import GlobalMusicPlayer from './GlobalMusicPlayer';
import tracks from '../music_data/tracks';

import './../styles/App.scss';

const Posts = () => {
  const { currentTrack } = useMusicPlayer();

  return (
    <section className="about">
      <div className="about-main">
        <div className="about-section">
          <div className="section-title"><h2>/posts</h2></div>
          <div className="section-content">
            <p>A collection of my thoughts, writings, and musings.</p>
          </div>
        </div>
        
        <div className="about-section">
          <div className="section-title"><h2>2025</h2></div>
          <div className="section-content">
            <ul>
              <li><p><NavLink to="/posts/hello">Hello</NavLink></p></li>
            </ul>
          </div>
        </div>

        <div className="about-section">
          <div className="section-title"><h2>2020</h2></div>
          <div className="section-content">
            <ul>
            <li><p><NavLink to="/posts/identity">Identity and Growth</NavLink></p></li>
            </ul>
          </div>
        </div>
        <div className="about-section">
          <div className="section-title"><h2>2018</h2></div>
          <div className="section-content">
            <ul>
            <li><p><NavLink to="/posts/platinum">Pursuing Platinum</NavLink></p></li>
            </ul>
          </div>
        </div>
      </div>

      <div className="about-sidebar">
        <div className="about-sidebar-img">
          <Animation />
          {currentTrack && (
          <div className="about-sidebar-player">
            <GlobalMusicPlayer tracks={tracks} />
          </div>
        )}
        </div>
       
        <br />
      </div>
    </section>
  );
};

export default Posts;
