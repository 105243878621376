import React from 'react';
import { NavLink } from "react-router-dom";

import './../styles/App.scss';
import './../styles/glitch.scss';
    
function Header(props) {

  return (
    <header>
        <div>
            <NavLink exact to="/" className="bolder" >
                <span data-text="adhiv.com">adhiv.com</span>
            </NavLink>
            <div className="nav-links">
                <ul>
                    <li><NavLink to="/about" activeClassName="activeLinkStyle" >About</NavLink></li>
                    <li><NavLink exact to="/now" activeClassName="activeLinkStyle" >Now</NavLink></li>
                    <li><NavLink to="/music" activeClassName="activeLinkStyle" >Music</NavLink></li>
                    <li><NavLink to="/prev" activeClassName="activeLinkStyle" >Prev</NavLink></li>
                    <li><NavLink to="/posts" activeClassName="activeLinkStyle" >Posts</NavLink></li>

                </ul>
            </div>
        </div>
       
    </header>
  );
}

export default Header;
