import React, { useState, useEffect } from 'react';
import MarkdownView from 'react-showdown';
import { useParams } from 'react-router-dom';
import matter from 'gray-matter';
import { Buffer } from 'buffer';
import { useMusicPlayer } from '../context/MusicPlayerContext';
import GlobalMusicPlayer from './GlobalMusicPlayer';
import tracks from '../music_data/tracks';
import '../styles/Post.scss';

// Polyfill Buffer for the browser environment
window.Buffer = Buffer;

function PostPage() {
  const [markdown, setMarkdown] = useState('');
  const [frontmatter, setFrontmatter] = useState({});
  const [decorativeText, setDecorativeText] = useState('');
  const { slug } = useParams();
  const { currentTrack } = useMusicPlayer();

  // Custom image parser for markdown
  const imageParser = {
    type: 'output',
    regex: /\!\[(.*?)\]\((\.\.\/assets\/.*?)\)/g,
    replace: (match, alt, path) => {
      // Convert ../assets/image.png to /assets/image.png
      const newPath = path.replace('../assets', '/assets');
      return `<img src="${newPath}" alt="${alt}" />`;
    }
  };

  const getRandomDecorativeText = () => {
    const patterns = [
      '////////////\\\\\\\\\\\\\\\\\\\\\\\\',
      '°º¤ø„¸¸„ø¤º°ˆ°º¤ø„¸¸„ø¤º°',
      '(...::..::..::...)',
      '( o.o )',
      '///(( -.- ))\\\\\\',
      '▼▲▼◥◤▼▲▼',
      '::▀▄▀▄░░▀▄▀▄::',
      '°º¤ø„¸§¸„ø¤º°'
    ];
    return patterns[Math.floor(Math.random() * patterns.length)];
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    // Handle different date formats more reliably
    let date;
    
    // Try to parse the date considering various formats
    if (dateString.includes('-')) {
      // Handle ISO format (YYYY-MM-DD)
      const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
      date = new Date(year, month - 1, day); // month is 0-indexed in JS Date
    } else if (dateString.includes('/')) {
      // Handle MM/DD/YYYY format
      const [month, day, year] = dateString.split('/').map(num => parseInt(num, 10));
      date = new Date(year, month - 1, day);
    } else {
      // Fallback to standard parsing
      date = new Date(dateString);
    }
    
    // Check if date is valid before using its methods
    if (isNaN(date.getTime())) {
      console.error('Invalid date:', dateString);
      return 'Invalid date';
    }
    
    const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    return `${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
  };

  useEffect(() => {
    setDecorativeText(getRandomDecorativeText());
    // Dynamically import markdown files
    import(`../posts/${slug}.md`)
      .then(res => {
        fetch(res.default)
          .then(response => response.text())
          .then(text => {
            // Parse frontmatter
            const { data, content } = matter(text);
            setFrontmatter(data);
            setMarkdown(content);
          });
      })
      .catch(err => console.error(err));
  }, [slug]);

  return (
    <section className="about post">
      <div className="about-main">
        <div className="about-section">
          <div className="section-title">
            <h2>{frontmatter.decorativeText && frontmatter.decorativeText}</h2>
          </div>
          <div className="section-content">
          {frontmatter.title && <h3>{frontmatter.title}</h3>}
          {frontmatter.date && <h2 className="post-date">{formatDate(frontmatter.date)}</h2>}
            <MarkdownView 
              markdown={markdown}
              options={{ 
                tables: true, 
                emoji: true,
                strikethrough: true,
                underline: true,
                extensions: [imageParser]
              }}
            />
          </div>
        </div>
      </div>
      <div className="about-sidebar">
        <div className="about-sidebar-img">
        {/* {frontmatter.tags && (
          <div className="tags">
            <h3>Tags</h3>
            <ul>
              {frontmatter.tags.map((tag, index) => (
                <li key={index}>{tag}</li>
              ))}
            </ul>
          </div>
        )} */}
        {currentTrack && (
          <div className="about-sidebar-player">
            <GlobalMusicPlayer tracks={tracks} />
          </div>
        )}
        </div>
      </div>
    </section>
  );
}

export default PostPage; 