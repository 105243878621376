import React, { useState, useEffect } from 'react';
import { useMusicPlayer } from '../context/MusicPlayerContext';
import GlobalMusicPlayer from './GlobalMusicPlayer';
import tracks from '../music_data/tracks';
import './../styles/MobilePlayer.scss';
import { useLocation } from 'react-router-dom';

const MobileFloatingPlayer = () => {
  const { currentTrack, isPlaying, togglePlayPause, currentTime, duration } = useMusicPlayer();
  const [expanded, setExpanded] = useState(false);
  const [initialTrackSet, setInitialTrackSet] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  // Only collapse on initial track selection, not when navigating between tracks
  useEffect(() => {
    if (currentTrack && !initialTrackSet) {
      // Only collapse when first starting playback
      setExpanded(false);
      setInitialTrackSet(true);
    }
  }, [currentTrack, initialTrackSet]);

  if (!currentTrack) return null;

  const toggleExpanded = (e) => {
    if (e) e.stopPropagation();
    setExpanded(!expanded);
    
    // If we're expanding, mark that we've interacted with the player
    if (!expanded) {
      setInitialTrackSet(true);
    }
  };

  // Calculate progress percentage for the mini progress bar
  const progressPercentage = duration ? (currentTime / duration) * 100 : 0;

  return (
    <div className={`mobile-floating-player ${expanded ? 'expanded' : 'collapsed'} ${isHomePage ? 'on-home-page' : ''}`}>
      {expanded ? (
        // Expanded view - show full player
        <div className="expanded-player">
          <div className="header-bar">
            <button className="collapse-btn" onClick={toggleExpanded}>
              <i className="fas fa-chevron-down"></i>
            </button>
          </div>
          <GlobalMusicPlayer tracks={tracks} />
        </div>
      ) : (
        // Simple view - minimal player
        <div className="simple-player" onClick={toggleExpanded}>
          <div className="simple-player-content">
            <div className="player-album">
              <img src={currentTrack.album_cover} alt={currentTrack.song_name} />
            </div>
            <div className="player-info">
              <div className="song-title">{currentTrack.song_name}</div>
              <div className="song-artist">{currentTrack.artist}</div>
            </div>
            <button 
              className="play-pause-btn" 
              onClick={(e) => {
                e.stopPropagation();  // Prevent expansion when clicking play/pause
                togglePlayPause();
              }}
              aria-label={isPlaying ? "Pause" : "Play"}
            >
              <i className={`fas ${isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
            </button>
          </div>
          <div className="mini-progress-bar">
            <div 
              className="progress-fill" 
              style={{ width: `${progressPercentage}%` }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileFloatingPlayer; 