import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Try multiple scroll methods for maximum browser compatibility
    const scrollToTop = () => {
      // Method 1: scrollTo
      if (window.scrollTo) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
      
      // Method 2: scroll
      if (window.scroll) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
      
      // Method 3: scrollTop (for older browsers)
      if (document.documentElement.scrollTop || document.body.scrollTop) {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
      }
    };

    // Small delay to ensure DOM is ready
    setTimeout(scrollToTop, 0);
  }, [pathname]);

  return null;
}

export default ScrollToTop; 