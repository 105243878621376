import React, { useEffect, useRef, useState } from 'react';
import './../styles/SongContextMarquee.scss';

const SongContextMarquee = ({ text }) => {
  const contentRef = useRef(null);
  const [animationDuration, setAnimationDuration] = useState(15);

  useEffect(() => {
    if (contentRef.current) {
      const textWidth = contentRef.current.scrollWidth;
      // Calculate duration based on text width (faster for shorter text)
      // Using 50px per second as base speed (slower than before)
      const duration = Math.max(10, Math.min(30, textWidth / 50));
      setAnimationDuration(duration);
    }
  }, [text]);

  if (!text) return null;

  return (
    <div className="song-context-marquee">
      <div 
        className="song-context-content"
        ref={contentRef}
        style={{ animationDuration: `${animationDuration}s` }}
      >
        <span className="text-segment">{text}</span>
        <span className="text-segment">{text}</span>
      </div>
    </div>
  );
};

export default SongContextMarquee; 