import React, { useEffect } from 'react';
import { useMusicPlayer } from '../context/MusicPlayerContext';
import SongContextMarquee from './SongContextMarquee';
import './../styles/MusicPlayer.scss';

const GlobalMusicPlayer = ({ tracks }) => {
  const {
    currentTrack,
    isPlaying,
    duration,
    currentTime,
    volume,
    handleTrackSelect,
    togglePlayPause,
    handlePrevious,
    handleNext,
    handleTimeUpdate,
    handleLoadedMetadata,
    handleSeek,
    handleVolumeChange,
    formatTime,
    audioRef
  } = useMusicPlayer();

  // Add event listeners for time updates
  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
      audio.addEventListener('ended', () => handleNext(tracks));
    }

    return () => {
      if (audio) {
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        audio.removeEventListener('ended', () => {});
      }
    };
  }, [currentTrack, handleTimeUpdate, handleLoadedMetadata, handleNext, tracks]);

  // Only render if there's a track currently selected
  if (!currentTrack) return null;

  return (
    <div className="global-music-player">
      <div className="music-player">
        <div className="player-header">
          <div className="player-album">
            <img src={currentTrack.album_cover} alt={currentTrack.song_name} />
          </div>
          
          <div className="player-info">
            <div className="song-title">{currentTrack.song_name}</div>
            <div className="song-artist">{currentTrack.artist}</div>
            {currentTrack.context && (
              <SongContextMarquee text={currentTrack.context} />
            )}
            {currentTrack.links && currentTrack.links.length > 0 && (
              <div className="song-links">
                {currentTrack.links.map((link, index) => (
                  <a 
                    key={index} 
                    href={link.url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="external-link"
                  >
                    {link.name}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
        
        <div className="player-controls">
          <div className="control-buttons">
            <button onClick={() => handlePrevious(tracks)} className="control-btn prev-btn">
              <i className="fas fa-step-backward"></i>
            </button>
            <button onClick={togglePlayPause} className="control-btn play-btn">
              <i className={`fas ${isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
            </button>
            <button onClick={() => handleNext(tracks)} className="control-btn next-btn">
              <i className="fas fa-step-forward"></i>
            </button>
          </div>
          
          <div className="seek-container">
            <span className="time current">{formatTime(currentTime)}</span>
            <input 
              type="range" 
              min="0" 
              max="100" 
              value={(duration ? (currentTime / duration) * 100 : 0)} 
              onChange={(e) => handleSeek(e.target.value)}
              className="seek-slider"
            />
            <span className="time total">{formatTime(duration)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalMusicPlayer; 