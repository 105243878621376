import React from 'react';

const DvdLogo = ({ color }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 67.417 29.523"
    width="100%" 
    height="100%"
  >
    <path 
      fill={color} 
      d="M59.646 0H43.07l-8.926 10.291L30.501 0H5.911L5 3.825h9.017c2.915 0 6.302 1.34 5.647 4.371-.729 3.37-4.19 5.01-10.474 5.01L11.011 5.1H4.727L2.04 16.758h8.516c5.601 0 13.115-1.64 15.165-7.969.551-1.702.496-3.225.11-4.508l.026-.001 5.738 16.395L46.35 3.825h9.381c2.915 0 5.618 1.33 5.01 4.371-.547 2.732-3.552 5.01-9.837 5.01L52.725 5.1H46.44l-2.687 11.658h6.968c5.601 0 14.299-1.64 16.348-7.969C68.764 3.555 63.927 0 59.646 0z"
    />
    <ellipse cx="31.686" cy="25.319" rx="31.686" ry="4.204" fill={color} />
    <ellipse cx="48.558" cy="25.114" rx="3.671" ry="2.608" fill="#fff" />
    <ellipse cx="48.558" cy="25.114" rx="2.397" ry="1.516" fill={color} />
    <path 
      fill="#fff" 
      d="M22.896 22.655h1.435v5.01h-1.435zM12.833 22.655h1.685l1.844 3.097 1.844-3.097h1.685l-2.983 5.01h-1.093zM30.501 22.657c2.045 0 3.703 1.156 3.703 2.525s-1.658 2.479-3.703 2.479h-1.958v-5.007l1.958.003z"
    />
    <path 
      fill={color} 
      d="M30.501 26.563c1.245 0 2.254-.58 2.254-1.381 0-.8-1.009-1.427-2.254-1.427h-.546v2.816l.546-.008z"
    />
    <path 
      fill="#fff" 
      d="M41.727 22.655h-4.155v5.01h4.155v-1.093h-2.721l-.002-.986h2.722v-1.028h-2.722l.002-.81h2.721z"
    />
  </svg>
);

export default DvdLogo; 