import React, { useEffect, useState, useRef } from 'react';

// Lighter pastel colors
const colors = [
    '#FF9AA2', // Pastel Red
    '#B5EAD7', // Pastel Green
    '#C7CEEA', // Pastel Blue
    '#FFDAC1', // Pastel Yellow/Orange
    '#E2F0CB', // Pastel Light Green
    '#CFBAF0', // Pastel Purple
    '#F1C0E8', // Pastel Pink
    '#A2D2FF'  // Pastel Light Blue
];

const CircleAnimation = () => {
    const [blackDots, setBlackDots] = useState(() => {
        const initialDots = [];
        for (let i = 0; i < 0; i++) {
            initialDots.push({
                id: Date.now() + i,
                x: Math.random() * window.innerWidth,
                y: Math.random() * window.innerHeight,
                size: Math.random() * (4 - 2) + 2,
                blur: Math.random() > 0.7 ? `${Math.random() * 1.5}px` : '0px',
                speed: Math.random() * (0.3 - 0.1) + 0.1
            });
        }
        return initialDots;
    });

    const [sparks, setSparks] = useState([]);
    const containerRef = useRef(null);
    const maxBlackDots = 0;

    const createBlackDot = () => {
        const containerWidth = containerRef.current?.offsetWidth || window.innerWidth;
        return {
            id: Date.now(),
            x: Math.random() * containerWidth,
            y: window.innerHeight,
            size: Math.random() * (4 - 2) + 2,
            blur: Math.random() > 0.7 ? `${Math.random() * 1.5}px` : '0px',
            speed: Math.random() * (0.3 - 0.1) + 0.1
        };
    };

    const createSparkCluster = () => {
        const containerWidth = containerRef.current?.offsetWidth || window.innerWidth;
        const containerHeight = window.innerHeight;
        
        // Create a single spawn point
        const spawnX = Math.random() * containerWidth;
        const spawnY = Math.random() * containerHeight;
        const sparkColor = colors[Math.floor(Math.random() * colors.length)];
        
        // Create 1-5 sparks from the same point (smaller, more varied clusters)
        const numParticles = Math.floor(Math.random() * 5) + 1;
        const sparks = [];

        // Base speed is 1.5x the black dot speed
        const baseSpeed = 0.45;

        for (let i = 0; i < numParticles; i++) {
            // More variation in vertical speed (±30% variation)
            const speedVariation = baseSpeed * (0.7 + Math.random() * 0.6);
            
            const spark = {
                id: Date.now() + Math.random() + i,
                x: spawnX,
                y: spawnY,
                size: Math.random() * (10 - 5) + 5,
                blur: Math.random() > 0.5 ? `${Math.random() * 2}px` : '0px',
                color: sparkColor,
                age: 0,
                maxAge: Math.random() * 150 + 300,
                
                // Motion parameters
                speed: speedVariation,
                
                // Even smaller wave parameters for tighter horizontal spread
                amplitude: Math.random() * 0.8 + 0.2,
                frequency: Math.random() * 0.006 + 0.003,
                phase: Math.random() * Math.PI * 2
            };
            sparks.push(spark);
        }
        return sparks;
    };

    const updateBlackDots = () => {
        setBlackDots(prevDots => {
            const filtered = prevDots.filter(dot => dot.y > -20);
            const updated = filtered.map(dot => ({
                ...dot,
                y: dot.y - dot.speed
            }));
            return updated;
        });
    };

    const updateSparks = () => {
        setSparks(prevSparks => {
            const filtered = prevSparks.filter(spark => 
                spark.y > -20 && spark.age < spark.maxAge
            );
            
            const updated = filtered.map(spark => {
                const newAge = spark.age + 1;
                const newY = spark.y - spark.speed;
                
                // Add very subtle sine wave motion
                const waveOffset = Math.sin(newY * spark.frequency + spark.phase) * 
                    (spark.amplitude * (1 - newAge / spark.maxAge));

                return {
                    ...spark,
                    x: spark.x + waveOffset,
                    y: newY,
                    age: newAge,
                    opacity: Math.max(0, 1 - (newAge / spark.maxAge) ** 1.5)
                };
            });
            return updated;
        });
    };

    useEffect(() => {
        let lastEventTime = 0;
        const eventCooldown = 50; // Minimum time between events in milliseconds

        const handleKeyDown = (e) => {
            const currentTime = Date.now();
            const key = e.key.toLowerCase();
            
            // Only create sparks if enough time has passed since the last event
            if ('asdfghjkl;\'wetyuop'.includes(key) && currentTime - lastEventTime > eventCooldown) {
                lastEventTime = currentTime;
                const newSparks = createSparkCluster();
                setSparks(prev => [...prev.filter(spark => spark.age < spark.maxAge), ...newSparks]);
            }
        };

        const handleNotePlay = () => {
            const currentTime = Date.now();
            
            // Only create sparks if enough time has passed since the last event
            if (currentTime - lastEventTime > eventCooldown) {
                lastEventTime = currentTime;
                const newSparks = createSparkCluster();
                setSparks(prev => [...prev.filter(spark => spark.age < spark.maxAge), ...newSparks]);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('notePlay', handleNotePlay);
        
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('notePlay', handleNotePlay);
        };
    }, []);

    useEffect(() => {
        const createInterval = setInterval(() => {
            setBlackDots(prev => {
                if (prev.length < maxBlackDots) {
                    return [...prev, createBlackDot()];
                }
                return prev;
            });
        }, 1500);

        const updateInterval = setInterval(() => {
            updateBlackDots();
            updateSparks();
        }, 16);

        return () => {
            clearInterval(createInterval);
            clearInterval(updateInterval);
        };
    }, []);

    return (
        <div 
            ref={containerRef}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
                zIndex: 1
            }}
        >
            {/* Render black dots */}
            {blackDots.map(dot => (
                <div
                    key={dot.id}
                    style={{
                        position: 'absolute',
                        left: `${dot.x}px`,
                        top: `${dot.y}px`,
                        width: `${dot.size}px`,
                        height: `${dot.size}px`,
                        borderRadius: '50%',
                        backgroundColor: '#000',
                        filter: dot.blur !== '0px' ? `blur(${dot.blur})` : 'none'
                    }}
                />
            ))}
            {/* Render colored sparks */}
            {sparks.map(spark => (
                <div
                    key={spark.id}
                    style={{
                        position: 'absolute',
                        left: `${spark.x}px`,
                        top: `${spark.y}px`,
                        width: `${spark.size}px`,
                        height: `${spark.size}px`,
                        borderRadius: '50%',
                        backgroundColor: spark.color,
                        opacity: spark.opacity,
                        filter: spark.blur !== '0px' ? `blur(${spark.blur})` : 'none',
                        transition: 'opacity 0.1s ease-out'
                    }}
                />
            ))}
        </div>
    );
};

export default CircleAnimation; 